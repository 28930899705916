import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { UserData } from "../../models/Authentication/UserData";
import { AuthenticationService } from '../../services/authentication.service';
import { MenuAuth } from "src/app/models/Authentication/MenuAuth";

@Injectable({
  providedIn: "root",
})
export class AdminGuard  {
  
  menuAuths: Array<MenuAuth> | any;
  
  constructor(public router: Router, public router1: ActivatedRoute, public authenticationService : AuthenticationService,) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not    
    if (this.authenticationService.isloggedin()) {      
      let permitted : boolean = false;
      this.menuAuths = this.authenticationService.getMenuAuth();
      this.menuAuths = JSON.parse(this.menuAuths);        

      let menuPath = 'URL empty';  

      if (route.url.length > 0)
      {
        //let menu = route.url[0].path;
        menuPath = this.getResolvedUrl(route); 
        //console.log(menuPath);
        if(this.authenticationService.getMenuAuth())
        {
          this.menuAuths = this.authenticationService.getMenuAuth();
          this.menuAuths = JSON.parse(this.menuAuths);            
          
          this.menuAuths.forEach(function(value : MenuAuth, index : any) {  
            if(value.NavigateURL === menuPath || '/dashboard/default' === menuPath || '/chart/ngx-chart' === menuPath || '/dashboard/profile' === menuPath)
            {
              permitted = true;
            } 
          }); 

          if(permitted){
            return true;
          }else
          {            
            this.router.navigate(['error/401']);
            return false;
          }
        }
        else 
        {          
          this.router.navigate(['error/401']);
          return false;
        }          
      } 
      else
      {        
        this.router.navigate(['error/500']);
          return false;
      }      
    }
    else{

      this.router.navigate(["/auth/login"]);
      return false;
    } 
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/').split('//').join('/');
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

    baseAPI = environment.baseAPI; 

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': environment.httpOpt.contentType,
            'Access-Control-Allow-Origin': environment.httpOpt.allowOrigin,            
        })
    };    

    constructor(private http:HttpClient) { }

    login(user : any) {
        return this.http.post(this.baseAPI + '/Authentication/Token', user, this.httpOptions);
    }

    getMenuAuth(){        
        return localStorage.getItem('MenuAuths')!=null?localStorage.getItem('MenuAuths'):[]; 
    }


    isloggedin(){

        let currentDate = new Date();
        let expiredDate = new Date(localStorage.getItem('Expires_Date')?.toString() || '');          

        if(currentDate >= expiredDate || !localStorage.getItem('IsActive') || !localStorage.getItem('Id'))
        {
            return false;
        }
        else
        {
            return true;
        }          
    }

    getUserId(){        
        return localStorage.getItem('Id')!=null?localStorage.getItem('Id')?.toString():'';
    }

    getName(){        
        return localStorage.getItem('Name')!=null?localStorage.getItem('Name')?.toString():'';
    }

    getEmail(){        
        return localStorage.getItem('Email')!=null?localStorage.getItem('Email')?.toString():'';
    }

    getUserName(){        
        return localStorage.getItem('UserName')!=null?localStorage.getItem('UserName')?.toString():'';
    }

    getAccessToken(){        
        return localStorage.getItem('AccessToken')!=null?localStorage.getItem('AccessToken')?.toString():'';
    }

    getRefreshToken(){        
        return localStorage.getItem('RefreshToken')!=null?localStorage.getItem('RefreshToken')?.toString():'';
    }

    getShortCode(){        
        return localStorage.getItem('ShortCode')!=null?localStorage.getItem('ShortCode')?.toString():'';
    }

    getAvatar(){        
        return localStorage.getItem('Avatar')!=null?localStorage.getItem('Avatar')?.toString():'';
    }
    getLang(){        
        return localStorage.getItem('Lang')!=null?localStorage.getItem('Lang')?.toString():'';
    }

    getDepartment(){        
        return localStorage.getItem('Department')!=null?localStorage.getItem('Department')?.toString():'';
    }

    getRoles(){
        //return 'admin';
        return localStorage.getItem('username')!=null?localStorage.getItem('username')?.toString():'';
    }

    logout(): void {
        localStorage.removeItem("Id");
        localStorage.removeItem("Name");
        localStorage.removeItem("Email");
        localStorage.removeItem("UserName");
        localStorage.removeItem("AccessToken");
        localStorage.removeItem("RefreshToken"); 
        localStorage.removeItem("Expires_Date"); 
        localStorage.removeItem("IsActive");
        localStorage.removeItem("Roles");
        localStorage.removeItem("Permissions");
        localStorage.removeItem("ShortCode");
        localStorage.removeItem("Avatar");
        localStorage.removeItem("Lang");
        localStorage.removeItem("Department");
        localStorage.clear();
    }

    convertStringToDate(value : string)
    {
        if(!value)
          return new Date();

        let date_time = value.split(' ');
        let date = date_time[0];
        let dateObj = date.split('-');        
        let time = date_time[1];        
        let timeObj = time.split(':');

        return new Date(parseInt(dateObj[2]), parseInt(dateObj[1]) - 1, parseInt(dateObj[0]), parseInt(timeObj[0]),parseInt(timeObj[1]));
    }
  

}
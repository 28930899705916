import {Pipe, PipeTransform} from '@angular/core';
import { StandardsComponent } from 'src/app/components/settings/standards/standards.component';
import {  Standard } from 'src/app/models/Testingdeclarations/Standard';

@Pipe({name: 'convertstring'})

export class StringPipe implements PipeTransform {
    transform(value: string) {
        if(value){
            let result : Array<Standard> = [];
            result = JSON.parse(value);            
            let resultArr : Array<string> = [];
            result.forEach(function(value1: Standard, index : any) 
            {   
                resultArr.push('('+value1.id+')')                   
            });

            return resultArr.join(' ');
        }        
        else 
        {
            return value;
        }
    }
}
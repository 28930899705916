import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

type UserFields = "email" | "password";
type FormErrors = { [u in UserFields]: string };

import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/authentication.service'
import { LoginResult } from '../../models/Authentication/LoginResult';
import { UserData } from "../../models/Authentication/UserData";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  public loginForm: FormGroup;

  public errorMessage: any;

  public loginResult : LoginResult | any; 
  public IsBusy = false;
  public loginText : string;
  public userData : UserData;
  isLoad = false;

  constructor(
    private fb: FormBuilder, 
    public router: Router, 
    public authenticationService : AuthenticationService, 
    private toastrService: ToastrService) 
    {
    this.loginForm = this.fb.group({      
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });
    this.loginText = "Login Now";
  }

  ngOnInit() {      

      if(this.authenticationService.isloggedin()){

        this.router.navigate(["/dashboard/default"]);
      }
      else{  
                
          this.authenticationService.logout();
      }
               
   }

  login() {
    this.IsBusy = true;
    this.loginText = "Loading...";
    this.isLoad = true;
    
    if (!this.loginForm.value['email'] || !this.loginForm.value['password']) {
      this.loginText = "Login Now";
      console.log("Username or password must be not empty!");

      let user = {
        email: "Test@gmail.com",
        password: "test123",
        name: "test user",
      };
      localStorage.setItem("user", JSON.stringify(user));
      this.isLoad = false;
      //this.router.navigate(["/dashboard/default"]);
    }
    else
    { 
      
      let user = {
        username : this.loginForm.value['email'],
        password: this.loginForm.value['password'],
        granttype : 'password'
      }      
      this.authenticationService.login(user).subscribe(item => {
        this.loginResult = item;        

        if(!this.loginResult.IsSuccess){
          this.toastrService.error('Ooh, một vài điều lỗi xảy ra, vui lòng kiểm tra lại thông tin', 'Error!'); 

          setTimeout(()=>{
            this.loginText = "Login Now";
            this.isLoad = false;
          }, 2000)         
          
          return;
        }

        setTimeout(()=>{
          this.loginText = "Login Now";
          this.isLoad = false;
        }, 2000)

        let currentDate = new Date();        
        currentDate.setDate(currentDate.getDate());
        currentDate.setSeconds(currentDate.getSeconds() + parseInt(this.loginResult.Data.Expires_Int));  

        localStorage.getItem("Id");
        localStorage.setItem("Id", this.loginResult.Data.Id);
        localStorage.getItem("Name");
        localStorage.setItem("Name", this.loginResult.Data.Name);
        localStorage.getItem("Email");
        localStorage.setItem("Email", this.loginResult.Data.Email);
        localStorage.getItem("UserName");
        localStorage.setItem("UserName", this.loginResult.Data.UserName);
        localStorage.getItem("AccessToken");
        localStorage.setItem("AccessToken", this.loginResult.Data.AccessToken);
        localStorage.getItem("RefreshToken");
        localStorage.setItem("RefreshToken", this.loginResult.Data.RefreshToken); 
        localStorage.getItem("Expires_Date");
        localStorage.setItem("Expires_Date", currentDate.toString()); 
        localStorage.getItem("IsActive");
        localStorage.setItem("IsActive", this.loginResult.Data.IsActive);
        localStorage.getItem("Roles");
        localStorage.setItem("Roles", JSON.stringify(this.loginResult.Data.Roles));
        localStorage.getItem("Permissions");
        localStorage.setItem("Permissions", JSON.stringify(this.loginResult.Data.Permissions));
        localStorage.getItem("MenuAuths");
        localStorage.setItem("MenuAuths", JSON.stringify(this.loginResult.Data.MenuAuths));
        localStorage.getItem("ShortCode");
        localStorage.setItem("ShortCode",this.loginResult.Data.Short_Code);
        localStorage.getItem("Avatar");
        localStorage.setItem("Avatar", this.loginResult.Data.Avatar);
        localStorage.getItem("Lang");
        localStorage.setItem("Lang", this.loginResult.Data.Lang);
        localStorage.getItem("Department");
        localStorage.setItem("Department", JSON.stringify(this.loginResult.Data.Department));        
        
        this.router.navigate(["/dashboard/default"]); 
      });       
    }
  }

  loginFacebook(){

  }
  loginTwitter(){

  }
  loginGoogle(){

  }
}

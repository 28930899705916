<div class="page-wrapper">
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="text-center"><img src="assets/images/edc-logo.png" alt="" height="100px" /></div>
      <div class="card mt-4">
        <div class="card-body">
          <div class="text-center">
            <h4>LOGIN</h4>
            <h6>Enter your Username and Password</h6>
          </div>
          <form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
            <div class="form-group">
              <label class="col-form-label pt-0">Email</label>
              <input class="form-control" formControlName="email" type="email" required="" placeholder="Username"/>
              <div *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['required']" class="text text-danger mt-1">Email is required</div>
              <div *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['email']" class="text text-danger mt-1">Invalid Email</div>
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input class="form-control" type="password" formControlName="password" required="" placeholder="Password" />
              <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']" class="text text-danger mtz-1">Password is required</div>
            </div>            
            <div class="form-group row mt-3 mb-0">
              <button class="btn btn-primary btn-block btn-lg" [disabled]="!loginForm.valid" (click)="login()" type="submit">
                <i *ngIf="isLoad" class="fa fa-spin fa-spinner" style="color:white"></i> <span> {{loginText}}</span>
              </button>
            </div>            
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

export const environment = {
  production: true,
  
  baseAPI : "https://api.sackytienphong.vn/api/v1",
    httpOpt : {
        contentType : 'application/json',
        contentTypeX : 'application/x-www-form-urlencoded',
        authorization : 'Bearer ',
        allowOrigin : '*,*',
    },   

  imageURL: "assets/images",
  version : "1.1.52"
};

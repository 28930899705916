import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncate'})

export class SupPipe implements PipeTransform {
    transform(value: string, length: number, symbol: string) {
        if(value){
            let arrayObject : Array<string> = [];
            let arrayResult : Array<string> = [];

            arrayObject = value.split('^');

            arrayObject.forEach(function(value : string, index : any) 
            { 
                if(index < 1)
                {
                    arrayResult.push(value);              
                }
                else
                {
                    let arraySup = value.split(' ');
                    //console.log(arraySup);
                    if(arraySup.length == 1 || (!arraySup[1] && arraySup.length == 1))
                    {
                        arrayResult.push(value.replace(value,'<sup>'+ value.trim() + '</sup>'));
                    }
                    else
                    {                        
                        arrayResult.push(arraySup[0].replace(arraySup[0],'<sup>'+ arraySup[0] + '</sup> ' + arraySup.slice(1, arraySup.length).join(' ')));
                        
                    }                    
                }   
            }); 
            //console.log(arrayResult);
            return arrayResult.slice(0, arrayResult.length).join('');
        }        
        else 
        {
            return value;
        }
    }
}
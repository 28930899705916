import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule, HttpLoaderFactory } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { DecimalPipe, DatePipe } from "@angular/common";
import { SupPipe } from "./customize/custom-sup/sup-pipe";
import { StringPipe } from "./customize/string-pipe";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { TreeModule } from 'primeng/tree';
import { ButtonModule } from 'primeng/button'; 

import { AdminGuard } from "./shared/guard/admin.guard";
import { CookieService } from "ngx-cookie-service";
import "hammerjs";
import "mousetrap";
@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    FormsModule,    
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TreeModule,
    ButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'vi'
    }),
  ],
  providers: [ AdminGuard, CookieService, DecimalPipe, DatePipe, SupPipe,StringPipe, provideNgxMask()],
  bootstrap: [AppComponent],
})
export class AppModule {}

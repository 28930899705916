<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyright 2024 © EDC PR All rights reserved. Version: {{version}}</p>
    </div>

    <div class="col-md-6">
      <!-- <p class="pull-right mb-0">sackytienphong.com<i class="fa fa-heart"></i></p> -->
      <p class="pull-right mb-0"><a href="https://sackytienphong.com" target="_blank">sackytienphong.com</a></p>
    </div>
  </div>
</div>
